import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import OpcoesPagamento from "../../../../components/OpcoesPagamento";
import CarrinhoPage from "../../../../components/CarrinhoPage";
import { useAuth } from "../../../../hooks/AuthContext";
import { addPagamento } from "../../../../api/Carrinho/Turismo";
import { Skeleton, Typography } from "@mui/material";
import { useState } from "react";
import { getlocalStorageTimerCarrinho } from "../../../../util/LocalStorage";
import { Timer } from "../../../../components";
import TiposPedido from "../../../../util/typesTipoPedido";
import useAlert from "../../../../hooks/useAlert";
import { StepComEntrega, StepSemEntrega } from "../../../../util/StepsTurismo";
import useLojaFechada from "../../../../hooks/useLojaFechada";
import usePedido from "../../../../hooks/usePedido";

export function Pagamento() {
  const navigation = useNavigate();
  const { user } = useAuth();
  const [loadingAddPagamento, setLoadingAddPagamento] = useState(false);
  const [dataLocal, setDataLocal] = useState(() =>
    getlocalStorageTimerCarrinho()
  );
  const { Alert, showAlertError } = useAlert();
  const [activeStep, setActiveStep] = useState(0);
  const [urlRetorno, setUrlRetorno] = useState("");
  const { lojaFechada } = useLojaFechada();
  const { itensPedido } = usePedido();
  const [isVenderSaldo, setIsVenderSaldo] = useState(false);

  const { idTipoPedido, isRecarga } = useParams();

  const [steps, setSteps] = useState([]);

  useEffect(() => {
    if (lojaFechada && lojaFechada.isFechada) navigation("/LojaFechada");
  }, [lojaFechada]);

  useState(() => {
    let valor = parseInt(idTipoPedido);

    if (valor === 0) {
      showAlertError(
        "Ops, algo deu errado, por favor selecione novamente o endereço - R2DC0"
      );
      setTimeout(() => {
        navigation(`/carrinho/turismo/entrega`);
      }, 2500);
    }

    if (JSON.parse(isRecarga)) {
      setUrlRetorno("/carrinho/turismo-compra/cartao-recarga/vitrine");
      setActiveStep(1);
      setSteps(StepSemEntrega);
      return;
    }

    setActiveStep(2);
    setSteps(StepComEntrega);
    configURLRetorno(idTipoPedido);
  }, [idTipoPedido, isRecarga, itensPedido]);

  const handlerNext = async (event, pagamento) => {
    event.preventDefault();
    setLoadingAddPagamento(true);
    const response = await addPagamento(user.id, pagamento);

    if (response && !response.errorMessage) {
      setTimeout(() => {
        navigation("/carrinho/turismo/itens");
      }, 2000);
    }
    setTimeout(() => {
      setLoadingAddPagamento(false);
    }, 2100);
  };

  const handleBack = () => {
    setLoadingAddPagamento(true);

    if (JSON.parse(isRecarga) || isVenderSaldo === false) {
      navigation(urlRetorno);
      return;
    }

    navigation("/carrinho/turismo/entrega");
  };

  function configURLRetorno(idTipoPedido) {
    switch (parseInt(idTipoPedido)) {
      case TiposPedido.compraEspecie:
        setUrlRetorno("/carrinho/turismo-venda/especie/vitrine");
        break;
      case TiposPedido.VendaEspecie:
        setUrlRetorno("/carrinho/turismo-compra/especie/vitrine");
        break;
      default:
        setUrlRetorno("/Home");
        break;
    }
  }

  useEffect(() => {
    if (parseInt(idTipoPedido) === TiposPedido.CompraEspecie) {
      if (itensPedido?.itens?.every((item) => item.moeda.isEspecie === false)) {
        setIsVenderSaldo(true);
        setActiveStep(1);
        setSteps(StepSemEntrega);
        setUrlRetorno(`/carrinho/turismo-venda/cartao-saldo/vitrine`);
      }
    }
  }, [itensPedido]);

  const handleBackVoltarComprar = () => {
    setLoadingAddPagamento(true);

    navigation(urlRetorno, {
      replace: false,
    });
  };

  return (
    <div>
      <Alert />
      <CarrinhoPage
        handleBack={handleBack}
        disableButtonNext={true}
        activeStep={activeStep}
        steps={steps}
        disabledVoltarCompras={true}
        handleBackVoltarComprar={handleBackVoltarComprar}
      >
        {!!dataLocal && (
          <Timer
            active={!!dataLocal}
            dataExpiracao={dataLocal}
            caminhoRedirect={"/CarrinhoVazio"}
          />
        )}

        {loadingAddPagamento ? (
          <Skeleton animation="wave" height={50} width={200} />
        ) : (
          <Typography
            style={{ fontSize: "18pt", marginBottom: "18pt", marginTop: 25 }}
          >
            {parseInt(idTipoPedido) === TiposPedido.CompraEspecie
              ? "Escolha como deseja receber"
              : "Forma de Pagamento"}
          </Typography>
        )}
        {console.log("itensPedido", itensPedido)}

        {itensPedido === null ? (
          <Skeleton animation="wave" height={50} width={200} />
        ) : (
          <OpcoesPagamento
            idTipoPedido={parseInt(idTipoPedido) ?? TiposPedido.VendaEspecie}
            onNext={handlerNext}
            loadingAddPagamento={loadingAddPagamento}
            idPraca={itensPedido !== null ? itensPedido.praca.id : 0}
          />
        )}
      </CarrinhoPage>
    </div>
  );
}
